@mixin formElementStyleReset {
  min-width: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  border-radius: 0;
  appearance: none;
}
