.view-toggler {
  @include formElementStyleReset;

  border: 1px solid #aaa;
  background: #ddd;
  color: #333;
  font-size: 16px;
  border-radius: 6px;
  box-shadow: 0 2px 1px #eee;
  padding: 15px;
  margin-bottom: 1em;
  width: 100%;
  cursor: pointer;
}
